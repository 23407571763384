import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { Box, Flex, Select } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { months, years } from '../constants/commonConstant';

const AladinDatePicker = ({ selectedDate, setSelectDate }) => {
    const [month, setMonth] = useState(new Date(selectedDate).getMonth() + 1);
    const [year, setYear] = useState(new Date(selectedDate).getFullYear());
    const [date, setDate] = useState(new Date(selectedDate));

    const onChangeDate = d => {
        setDate(d);
        setSelectDate(dayjs(d).format('YYYY-MM-DD'));
    };

    const onChangeYear = e => {
        setYear(e.target.value);
        setSelectedDate({ y: e.target.value });
    };

    const onChangeMonth = e => {
        setMonth(e.target.value);
        setSelectedDate({ m: e.target.value });
    };

    const setSelectedDate = ym => {
        const { m, y } = ym;
        const daysInMonth = new Date(y ?? year, m ?? month, 0).getDate();
        const selectedDateDay = date.getDate();
        const finalDate = new Date(
            y ?? year,
            (m ?? month) - 1,
            Math.min(daysInMonth, selectedDateDay)
        );

        onChangeDate(finalDate);
    };

    return (
        <>
            <Box pb="10px" outline="none">
                <Flex direction="row">
                    <Select
                        bgColor="white"
                        value={month}
                        readOnly
                        w="120px"
                        h="30px"
                        pr={0}
                        name="month"
                        border="0px"
                        fontSize="14px"
                        _focusVisible={{
                            outline: 'none',
                        }}
                        onChange={onChangeMonth}
                    >
                        {months.map(({ value, key }) => (
                            <option value={Number(key)} key={key}>
                                {value}
                            </option>
                        ))}
                    </Select>

                    <Select
                        bgColor="white"
                        value={year}
                        readOnly
                        w="100px"
                        h="30px"
                        pr={0}
                        name="month"
                        border="0px"
                        fontSize="14px"
                        _focusVisible={{
                            outline: 'none',
                        }}
                        onChange={onChangeYear}
                    >
                        {years().map(y => (
                            <option value={y} key={y}>
                                {y}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Box>
            <DatePicker
                dateFormat="YYYY-MM-DD"
                inline
                selected={date}
                onChange={onChangeDate}
            />
        </>
    );
};

AladinDatePicker.propTypes = {
    selectedDate: PropTypes.string.isRequired,
    setSelectDate: PropTypes.func.isRequired,
};

export default AladinDatePicker;
