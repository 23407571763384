import React, { useEffect, useState } from 'react';

import { Box, Flex, Tag, TagLabel, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Download from '../../../common/dropDown/Download';
import MultipleSelectDropDown from '../../../common/dropDown/MultipleSelectDropDown';
import Search from './Search';

const Filter = ({
    approvedType,
    partners,
    leads,
    selectedItem,
    employee,
    searchEmployee,
    selectDownload,
}) => {
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);

    const handleLeadChange = React.useCallback(
        selectedIds => {
            setSelectedLeads(selectedIds);
            selectedItem({ type: 'leads', items: selectedIds });
        },
        [selectedItem]
    );

    const handlePartnerChange = React.useCallback(
        selectedIds => {
            setSelectedPartners(selectedIds);
            selectedItem({ type: 'partners', items: selectedIds });
        },
        [selectedItem]
    );
    const removeLead = employeeId => {
        const updatedLeads = selectedLeads.filter(
            item => item.employeeId !== employeeId
        );
        setSelectedLeads(updatedLeads);
        selectedItem({ type: 'leads', items: updatedLeads });
    };

    const removePartner = id => {
        const updatedPartners = selectedPartners.filter(item => item.id !== id);
        setSelectedPartners(updatedPartners);
        selectedItem({ type: 'partners', items: updatedPartners });
    };

    return (
        <>
            <Flex
                direction="row"
                gap="12px"
                pt="24px"
                pb="16px"
                justifyContent="space-between"
            >
                {approvedType !== 'autoApproved' && (
                    <Search
                        employee={employee}
                        getFilterData={searchEmployee}
                    />
                )}

                <MultipleSelectDropDown
                    data={leads}
                    placeholder="Leads"
                    onChange={handleLeadChange}
                    selectedItems={selectedLeads}
                    identifier="employeeId"
                />
                {approvedType !== 'autoApproved' && (
                    <MultipleSelectDropDown
                        data={partners}
                        placeholder="Partners"
                        onChange={handlePartnerChange}
                        selectedItems={selectedPartners}
                        identifier="id"
                    />
                )}
                <Download selectDownload={selectDownload} />
            </Flex>

            <Flex gap="12px">
                {selectedLeads.length > 0 && (
                    <Flex mb="14px" flexWrap="wrap" gap="4px">
                        <Text fontSize="14px">Selected Lead:</Text>
                        {selectedLeads.map(item => (
                            <Tag
                                key={item.id}
                                size="sm"
                                className="selectedTag"
                            >
                                <TagLabel>{item.name}</TagLabel>
                                <Box
                                    as="button"
                                    ml="2"
                                    onClick={() => removeLead(item.employeeId)}
                                >
                                    ✕
                                </Box>
                            </Tag>
                        ))}
                    </Flex>
                )}
                {selectedPartners.length > 0 && (
                    <Flex mb="14px" flexWrap="wrap" gap="4px">
                        <Text fontSize="14px">Selected Partner:</Text>
                        {selectedPartners.map(item => (
                            <Tag
                                key={item.id}
                                size="sm"
                                className="selectedTag"
                            >
                                <TagLabel>{item.name}</TagLabel>
                                <Box
                                    as="button"
                                    ml="2"
                                    onClick={() => removePartner(item.id)}
                                >
                                    ✕
                                </Box>
                            </Tag>
                        ))}
                    </Flex>
                )}
            </Flex>
        </>
    );
};

Filter.propTypes = {
    approvedType: PropTypes.string,
    selectedItem: PropTypes.any,
    searchEmployee: PropTypes.any,
    partners: PropTypes.any,
    leads: PropTypes.any,
    employee: PropTypes.any,
    selectDownload: PropTypes.any,
};

export default Filter;
