import React from 'react';

import * as Type from '../constants/actionTypeConstant';
import { INFORMATION_KEY } from '../constants/commonConstant';

const InformationContext = React.createContext();

function informationReducer(state, action) {
    switch (action.type) {
        case Type.SET_EMPLOYEES: {
            const result = action.payload;
            return { ...state, employeesResult: result };
        }
        case Type.SET_TEAMS: {
            const result = action.payload;
            return { ...state, teamsResult: result };
        }
        case Type.SET_TEAM_MEMBERS: {
            const result = action.payload;
            return { ...state, teamDetails: result };
        }
        case Type.IS_TOKEN_FOUND: {
            return { ...state, isTokenFound: action.payload };
        }
        case Type.CLEAR_INFORMATION: {
            localStorage.removeItem(INFORMATION_KEY);
            return { ...initialState };
        }
        case Type.SET_LOGIN_TYPE: {
            return { ...state, loginType: action.payload };
        }
        case Type.SET_LAST_LOGIN_TIME: {
            return { ...state, lastLoggedIn: action.payload };
        }
        case Type.FILTER_EMPLOYEES_BY_NAME_FOR_LEAVE: {
            return { ...state, filterByEmployeesNameForLeave: action.payload };
        }
        case Type.FILTER_DESIGNATION_FOR_LEAVE: {
            return { ...state, filterByDesignationForLeave: action.payload };
        }
        case Type.ADD_EMPLOYEE_LEAVE_RESULT: {
            return { ...state, employeesLeaveResult: action.payload };
        }
        case Type.IS_FILTER_OPTION_OPEN_FOR_LEAVE: {
            return { ...state, isFilterOptionOpenForLeave: action.payload };
        }
        case Type.IS_UPDATED_DATE_RANGE_FOR_FILTER: {
            return { ...state, isUpdatedDateRangeForFilter: action.payload };
        }
        case Type.SET_GOOGLE_LOGIN_TOKEN_ID: {
            return {
                ...state,
                googleLoginTokenId: action.payload,
            };
        }
        case Type.SET_ALL_DESIGNATION: {
            return {
                ...state,
                allDesignation: action.payload,
            };
        }
        case Type.SET_MENUBAR_WIDTH: {
            return {
                ...state,
                menubarWidth: action.payload,
            };
        }
        case Type.IS_MENUBAR_OPEN: {
            return {
                ...state,
                isMenubarOpen: action.payload,
            };
        }
        case Type.SET_HOLIDAY: {
            const result = action.payload;
            return { ...state, holidaysResult: result };
        }
        case Type.SET_APPROVED_LEAVE: {
            const result = action.payload;
            return { ...state, approvedLeavesResult: result };
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`);
        }
    }
}

const initialState = {
    employeesResult: null,
    teamsResult: null,
    teamDetails: {
        members: [],
        teamId: 0,
        teamName: '',
        teamDescription: '',
    },
    isTokenFound: false,
    loginType: '',
    lastLoggedIn: null,
    filterByEmployeesNameForLeave: [],
    filterByDesignationForLeave: [],
    employeesLeaveResult: null,
    isFilterOptionOpenForLeave: false,
    isUpdatedDateRangeForFilter: false,
    googleLoginTokenId: null,
    allDesignation: [],
    menubarWidth: '280px',
    isMenubarOpen: true,
    holidaysResult: [],
};

const localState = JSON.parse(localStorage.getItem(INFORMATION_KEY));

function InformationProvider(props) {
    const [state, dispatch] = React.useReducer(
        informationReducer,
        localState || initialState
    );

    React.useEffect(() => {
        localStorage.setItem(INFORMATION_KEY, JSON.stringify(state));
    }, [state]);
    const value = React.useMemo(() => [state, dispatch], [state]);
    return <InformationContext.Provider value={value} {...props} />;
}

function useInformation() {
    const context = React.useContext(InformationContext);
    if (!context) {
        throw new Error(
            'useInformation must be used within a InformationProvider'
        );
    }
    const [state, dispatch] = context;

    return { state, dispatch };
}

export { InformationProvider, useInformation };
