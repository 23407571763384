import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = date => {
    return dayjs(date).format('YYYY-MM-DD');
};

export const formatDateRange = (startDate, endDate) => {
    return [
        dayjs(startDate).format('YYYY-MM-DD'),
        dayjs(endDate).format('YYYY-MM-DD'),
    ];
};

export const getStartOfYear = year => {
    return dayjs(`${year}-01-01`).format('YYYY-MM-DD');
};

export const getEndOfYear = year => {
    return dayjs(`${year}-12-31`).format('YYYY-MM-DD');
};

const SANITIZE_DATE_REGEX = /\[.*?\]/g;

export const sanitizeDate = dateString => {
    return dateString.replace(SANITIZE_DATE_REGEX, '');
};

export const formatDateByZone = (startFrom, endAt) => {
    const formattedStart = dayjs
        .tz(sanitizeDate(startFrom), 'Asia/Dhaka')
        .format('DD MMM, YYYY');
    const formattedEnd = dayjs
        .tz(sanitizeDate(endAt), 'Asia/Dhaka')
        .format('DD MMM, YYYY');

    return formattedStart === formattedEnd
        ? formattedStart
        : `${formattedStart} - ${formattedEnd}`;
};

export const applyDate = applyDate => {
    const formattedDate = dayjs
        .tz(sanitizeDate(applyDate), 'Asia/Dhaka')
        .format('DD MMM, YYYY');
    return formattedDate;
};

export const getCurrentDate = () => {
    return dayjs().tz('Asia/Dhaka').startOf('day');
};

export const getHolidayStart = startFrom => {
    return dayjs.tz(sanitizeDate(startFrom), 'Asia/Dhaka').startOf('day');
};

export const getDayFromDate = date => {
    return dayjs(date).day();
};
export const getConvertDate = date => {
    return dayjs(date).toDate();
};
