import React, { useState } from 'react';

import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import HolidayManagement from '../../service/holiday-management';

function HolidayDeleteModal({ holidayDelete, onHolidayDeleted }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = React.useRef(null);
    const initialRef = React.useRef(null);
    const toast = useToast();
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);

    const handleModalClose = () => {
        onClose();
    };

    const onSubmit = async () => {
        setDataLoaderOpen(true);
        try {
            await HolidayManagement.deleteHoliday(holidayDelete.id);
            onHolidayDeleted(holidayDelete.id);
            toast({
                title: 'Successfully Deleted',
                description: 'Holiday has been deleted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onClose();
            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Delete Failed',
                description: error.message || 'An error occurred',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Flex align="center" onClick={onOpen} cursor="pointer">
                <Text>Delete</Text>
            </Flex>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={handleModalClose}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete holiday</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            <Text
                                color="#333"
                                fontSize="14px"
                                fontWeight="400"
                                py="24px"
                            >
                                Are you sure you want to delete the selected
                                holiday?
                            </Text>
                            <Flex direction="row" justifyContent="flex-end">
                                <Text
                                    cursor="pointer"
                                    fontSize="12px"
                                    pr="16px"
                                    color="#3F3F4E"
                                    onClick={handleModalClose}
                                    alignSelf="center"
                                >
                                    Cancel
                                </Text>
                                <Button
                                    w="119px"
                                    h="30px"
                                    fontSize="12px"
                                    bgColor="#E30000"
                                    color="white"
                                    type="submit"
                                    _hover={{
                                        bgColor: '#E30000',
                                        color: 'white',
                                    }}
                                >
                                    Yes, Delete
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

HolidayDeleteModal.propTypes = {
    holidayDelete: PropTypes.any.isRequired,
    onHolidayDeleted: PropTypes.func.isRequired,
};
export default HolidayDeleteModal;
