import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Avatar,
    Box,
    Button,
    Circle,
    Flex,
    Spacer,
    Text,
    useMediaQuery,
    WrapItem,
} from '@chakra-ui/react';
import { gapi } from 'gapi-script';

import { CLEAR_INFORMATION } from '../constants/actionTypeConstant';
import { useInformation } from '../contexts/informationContext';
import EmailDecoder from '../helper/email-decoder';
import logout from '../helper/logout';

function Navbar() {
    const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
    const [username] = useState(EmailDecoder());
    const navigate = useNavigate();
    const { dispatch } = useInformation();

    function onClickNavigateToLoginPage() {
        logout();
        dispatch({ type: CLEAR_INFORMATION, payload: null });
        navigate('/login');
    }
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    useEffect(() => {
        const initClient = () => {
            gapi.client
                .init({
                    clientId: clientId,
                    scope: 'https://www.googleapis.com/auth/contacts',
                })
                .then(() => {
                    window.gapi.auth2.getAuthInstance().signOut();
                });
        };
        gapi.load('client', initClient);
    }, []);

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            px={0}
            w="100%"
            h="64px"
            bgColor="#F2F2F2"
            zIndex={200}
        >
            <Flex>
                <Box pl={{ lg: 8, md: 6, sm: 4, base: 3 }} py={2}>
                    <Flex direction="row">
                        <Circle
                            p={6}
                            bgColor="#0077C0"
                            fontSize="37px"
                            fontWeight="900"
                            color="#FFF"
                            w="39px"
                            h="45px"
                        >
                            A
                        </Circle>
                        <Flex
                            direction="column"
                            pl={4}
                            color="#333"
                            fontSize="18px"
                        >
                            <Text fontWeight="700">Aladin</Text>
                            <Text fontWeight="300" lineHeight="3">
                                Admin Panel
                            </Text>
                        </Flex>
                    </Flex>
                </Box>

                <Spacer />

                <Box mr={{ lg: 8, md: 6, sm: 4, base: 3 }}>
                    <Flex pt="14px" direction="row" color="#333">
                        <Box
                        //onClick={() => navigate("/profile")}
                        >
                            <Flex direction="row">
                                {isLargerThan600 ? (
                                    <Text as="b" fontSize="14px" pt="10px">
                                        {username}
                                    </Text>
                                ) : (
                                    <></>
                                )}

                                <WrapItem px={{ lg: 6, base: 4 }}>
                                    <Avatar
                                        w={{ lg: '40px', base: '36px' }}
                                        h={{ lg: '40px', base: '36px' }}
                                        name={username}
                                    />
                                </WrapItem>
                            </Flex>
                        </Box>

                        <Button
                            py={0}
                            color="#FFFFFF"
                            bgColor="#0077C0"
                            border="1px solid"
                            borderColor="#FFFFFF"
                            borderRadius="0px"
                            _hover={{ bgColor: '#0077C0 !important' }}
                            onClick={onClickNavigateToLoginPage}
                        >
                            Logout
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

export default Navbar;
