/* eslint-disable */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Spacer,
    Text,
    useToast,
    Popover,
    PopoverTrigger,
    Image,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from '@chakra-ui/react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { yupResolver } from '@hookform/resolvers/yup';

import calendar from '../../assets/icons/date.svg';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import { ADD_EMPLOYEE_BREADCRUMB } from '../../constants/breadcrumbData';
import { EmployeeStatus } from '../../constants/employeeManagementConstant';
import { PERMANENT } from '../../constants/employeeTypes';
import { buildEmployeePath } from '../../constants/pathNameConstants';
import EmployeeManagement from '../../service/employee-management';
import PartnerManagement from '../../service/partner-management';
import { addEmployeeValidation } from '../../validation/dataValidation';
import AladinDatePicker from "../../common/AladinDatePicker";

function AddEmployee() {
    const PAGE_TITLE = 'Add Employee';

    const navigate = useNavigate();
    const toast = useToast();
    const [partners, setPartners] = useState([]);
    const [allDesignation, setAllDesignation] = useState([]);
    const [supervisorsNameAndId, setSupervisorNameAndId] = useState([]);
    const [supervisorName, setSupervisorName] = useState('');
    const [isSelectSupervisor, setSelectSupervisor] = useState(false);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [joinningDate, setJoinningDate] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addEmployeeValidation),
    });

    async function getSupervisorByNameHandler(name) {
        setSupervisorNameAndId([]);
        let uniqueSupervisorsNameAndId = new Set();

        if (name.length >= 1) {
            try {
                const response =
                    await EmployeeManagement.getEmployeesByNameAndStatus(
                        name,
                        PERMANENT
                    );
                for (let i = 0; i < response.content.length; i++) {
                    uniqueSupervisorsNameAndId.add({
                        name: response.content[i].name,
                        id: response.content[i].employeeId,
                    });
                }
                setSupervisorNameAndId([...uniqueSupervisorsNameAndId]);
            } catch (error) {
                toast({
                    title: 'Search fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }

    async function submit(values) {
        let booleanFlagProceedForAdd = true;
        // Editing the value of supervisor Id
        if (supervisorsNameAndId.length === 0) {
            booleanFlagProceedForAdd = false;
            toast({
                title: 'Selection fail',
                description: 'Please select a supervisor',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } else {
            const supervisorNameIdPair = supervisorsNameAndId.find(
                supervisor => {
                    return supervisor.name === supervisorName;
                }
            );
            if (supervisorNameIdPair) {
                values.supervisorId = supervisorNameIdPair.id;
            } else {
                booleanFlagProceedForAdd = false;
                toast({
                    title: 'Selection fail',
                    description: 'Please select a supervisor',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }

        if (booleanFlagProceedForAdd) {
            const payload = {
                ...values,
                joiningDate: joinningDate,
            };
            setDataLoaderOpen(true);
            try {
                const response = await EmployeeManagement.addEmployee(payload);
                setDataLoaderOpen(false);
                toast({
                    title: 'Successfully Added',
                    description: 'Employee has been added successful',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                navigate(buildEmployeePath(response.employeeId));
            } catch (error) {
                setDataLoaderOpen(false);
                toast({
                    title: 'Add fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }

    const fetchDesignation = async () => {
        try {
            const response = await EmployeeManagement.getAllDesignation();
            const designations = response.content.map(d => d.name);
            setAllDesignation(designations);
        } catch (error) {
            toast({
                title: 'Designation fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchPartners = async () => {
        try {
            const response = await PartnerManagement.getAllPartner();
            setPartners(response.data);
            setDataLoaderOpen(false);
        } catch (error) {
            toast({
                title: 'Partner fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchDesignation();
        fetchPartners();
    }, []);

    if (isDataLoaderOpen || !allDesignation)
        return <DataLoader isOpen={isDataLoaderOpen} />;

    return (
        <Box bgColor="#FFFFFF">
            <CustomBreadcrumb allBreadcrumbData={ADD_EMPLOYEE_BREADCRUMB} />

            <Box direction="row" my="16px">
                <PageTitle title={PAGE_TITLE} />
            </Box>
            <Flex
                pt={8}
                pl={2}
                mb="16px"
                justifyContent="center"
                alignItems="center"
                direction="column"
                fontSize="14px"
            >
                <form onSubmit={handleSubmit(submit)}>
                    <FormControl pt={2} isInvalid={errors.email}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="email"
                            color="#464646"
                        >
                            <Box color="#333333" pb={4}>
                                <Text as="b">Enter Employee Data</Text>
                            </Box>
                            Email
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Input
                                    {...register('email')}
                                    placeholder="Email"
                                    bgColor="white"
                                    color="gray.500"
                                    width="100%"
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    fontSize="14px"
                                    autoComplete="off"
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                    onChange={e =>
                                        register('email').onChange(e)
                                    }
                                />

                                <Flex>
                                    <FormErrorMessage>
                                        {errors.email && errors.email.message}
                                    </FormErrorMessage>
                                </Flex>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <FormControl pt={6} isInvalid={errors.employeeId}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="employeeId"
                            color="#464646"
                        >
                            Employee ID
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Input
                                    {...register('employeeId')}
                                    placeholder="##########"
                                    bgColor="white"
                                    color="gray.500"
                                    width="100%"
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    fontSize="14px"
                                    autoComplete="off"
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                    onChange={e =>
                                        register('employeeId').onChange(e)
                                    }
                                />

                                <Flex>
                                    <FormErrorMessage>
                                        {errors.employeeId &&
                                            errors.employeeId.message}
                                    </FormErrorMessage>
                                </Flex>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <FormControl pt={6} isInvalid={errors.name}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="name"
                            color="#464646"
                        >
                            Name
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Input
                                    {...register('name')}
                                    placeholder="Name"
                                    bgColor="white"
                                    color="gray.500"
                                    width="100%"
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    fontSize="14px"
                                    autoComplete="off"
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                    onChange={e => register('name').onChange(e)}
                                />
                                <Flex>
                                    <FormErrorMessage>
                                        {errors.name && errors.name.message}
                                    </FormErrorMessage>
                                </Flex>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <FormControl pt={6} isInvalid={errors.joiningDate}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="joiningDate"
                            color="#464646"
                        >
                            Joining Date
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <InputGroup>
                                    <InputRightElement
                                        // eslint-disable-next-line react/no-children-prop
                                        children={
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Image
                                                        w="15px"
                                                        h="15px"
                                                        src={calendar}
                                                    />
                                                </PopoverTrigger>
                                                <PopoverContent
                                                    w={[
                                                        '300px',
                                                        '450px',
                                                        'auto',
                                                    ]}
                                                >
                                                    <PopoverArrow bg="#0077C0" />
                                                    <PopoverBody
                                                        border="8px solid #0077C0"
                                                        p={0}
                                                        borderRadius="5px"
                                                    >
                                                        <Box
                                                            bg="#0077C0"
                                                            m="-1px"
                                                            h="10px"
                                                            w="100.7%"
                                                        >
                                                            <Box
                                                                ml="85px"
                                                                pt="0px"
                                                                w="60px"
                                                                bgColor="#FFFFFF"
                                                                borderRadius="10px"
                                                                border="2px solid #FFFFFF"
                                                            ></Box>
                                                        </Box>
                                                        <Box p="4px">
                                                            <Flex
                                                                direction={[
                                                                    'column',
                                                                    'row',
                                                                    'column',
                                                                ]}
                                                            >
                                                                <AladinDatePicker
                                                                    selectedDate={joinningDate ? joinningDate : dayjs().format('YYYY-MM-DD')}
                                                                    setSelectDate={setJoinningDate}/>
                                                            </Flex>
                                                        </Box>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        }
                                    />
                                    <Input
                                        {...register('joiningDate')}
                                        placeholder="Joining Date"
                                        bgColor="white"
                                        value={joinningDate}
                                        color="gray.500"
                                        width="100%"
                                        border="1px"
                                        borderColor="#DDDDDD"
                                        fontSize="14px"
                                        autoComplete="off"
                                        _focus={{
                                            borderColor: '#DDDDDD !important',
                                        }}
                                        onChange={e => {
                                            //setJoinningDate(e.target.value);
                                            register('joiningDate').onChange(e);
                                        }}
                                    />
                                </InputGroup>

                                <Flex>
                                    <FormErrorMessage>
                                        {joinningDate === '' &&
                                            'Joining date is required...'}
                                    </FormErrorMessage>
                                </Flex>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <FormControl mt={6}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="designation"
                            color="#464646"
                        >
                            Designation
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Select
                                    {...register('designation')}
                                    placeholder="Select Designation"
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    color="gray.500"
                                    fontSize="14px"
                                    isInvalid={errors.designation}
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                    _placeholder={{
                                        color: '#DDDDDD !important',
                                    }}
                                >
                                    {allDesignation.map((value, index) => (
                                        <option value={value} key={index}>
                                            {value}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        {errors.designation && (
                            <Text
                                color="#E53E3E"
                                mt={2}
                                fontSize="14px"
                                maxWidth={{ base: '96%', lg: '70%' }}
                                status="error"
                            >
                                {errors.designation &&
                                    errors.designation.message}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={6}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="status"
                            color="#464646"
                        >
                            Status
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Select
                                    {...register('status')}
                                    placeholder="Status"
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    color="gray.500"
                                    fontSize="14px"
                                    isInvalid={errors.status}
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                >
                                    {EmployeeStatus.map((value, index) => (
                                        <option value={value} key={index}>
                                            {value}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        {errors.status && (
                            <Text
                                color="#E53E3E"
                                mt={2}
                                fontSize="14px"
                                maxWidth={{ base: '96%', lg: '70%' }}
                                status="error"
                            >
                                {errors.status && errors.status.message}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={6}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="Supervisor Name"
                            color="#464646"
                        >
                            Supervisor Name
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <AutoComplete openOnFocus>
                                    <AutoCompleteInput
                                        value={supervisorName}
                                        variant="filled"
                                        {...register('supervisorId')}
                                        placeholder="Supervisor Name"
                                        border="1px"
                                        borderColor="#DDDDDD"
                                        bgColor="#FFFFFF"
                                        color="gray.500"
                                        fontSize="14px"
                                        autoComplete="off"
                                        isInvalid={errors.supervisorId}
                                        _focus={{
                                            borderColor: '#DDDDDD !important',
                                        }}
                                        onChange={e => {
                                            getSupervisorByNameHandler(
                                                e.target.value
                                            );
                                            setSupervisorName(e.target.value);
                                            setSelectSupervisor(false);
                                            register('supervisorId').onChange(
                                                e
                                            );
                                        }}
                                    />
                                    {supervisorsNameAndId.length > 0 ? (
                                        <AutoCompleteList>
                                            {supervisorsNameAndId.map(
                                                (supervisor, s_id) => (
                                                    <AutoCompleteItem
                                                        key={`option-${s_id}`}
                                                        value={supervisor.name}
                                                        onClick={() => {
                                                            setSupervisorName(
                                                                supervisor.name
                                                            );
                                                            setSelectSupervisor(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {supervisor.name}
                                                    </AutoCompleteItem>
                                                )
                                            )}
                                        </AutoCompleteList>
                                    ) : (
                                        <></>
                                    )}
                                </AutoComplete>
                            </Flex>
                        </Flex>
                        {errors.supervisorId && (
                            <Text
                                color="#E53E3E"
                                mt={2}
                                fontSize="14px"
                                maxWidth={{ base: '96%', lg: '70%' }}
                                status="error"
                            >
                                {errors.supervisorId &&
                                    errors.supervisorId.message}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={6}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="partnerId"
                            color="#464646"
                        >
                            Partner Name
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Select
                                    {...register('partnerId')}
                                    placeholder="Partner Name"
                                    _placeholder={{
                                        color: 'gray.400 !important',
                                        fontSize: '5px',
                                    }}
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    color="gray.500"
                                    fontSize="14px"
                                    isInvalid={errors.partnerId}
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                >
                                    {partners.map(({ id, name }) => (
                                        <option value={id} key={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        {errors.partnerId && (
                            <Text
                                color="#E53E3E"
                                mt={2}
                                fontSize="14px"
                                maxWidth={{ base: '96%', lg: '70%' }}
                                status="error"
                            >
                                {errors.partnerId && errors.partnerId.message}
                            </Text>
                        )}
                    </FormControl>

                    <Flex
                        direction="row"
                        mt="300px"
                        mb="54px"
                        w={{ lg: '486px', base: '96%' }}
                    >
                        <Text
                            cursor="pointer"
                            onClick={() => navigate('/employees/0/10')}
                        >
                            Cancel
                        </Text>
                        <Spacer />
                        <Button
                            //onSubmit={submit}
                            w="119px"
                            h="30px"
                            fontSize="12px"
                            bgColor="#0077C0"
                            color="white"
                            type="submit"
                            disabled={
                                !isValid ||
                                !isSelectSupervisor ||
                                joinningDate.length === 0
                            }
                            _focus={{
                                borderColor: 'none',
                            }}
                            _hover={{ color: 'white', bgColor: '#0077C0' }}
                            _disabled={{
                                cursor: 'not-allowed',
                                bgColor: '#DDDDDD !important',
                                color: '#9A9A9A !important',
                            }}
                        >
                            Add Employee
                        </Button>
                    </Flex>
                </form>
            </Flex>
        </Box>
    );
}

export default AddEmployee;
