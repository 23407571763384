import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';

import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import { InformationProvider } from './contexts/informationContext';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <BrowserRouter>
            <ChakraProvider theme={theme}>
                <InformationProvider>
                    <App />
                </InformationProvider>
            </ChakraProvider>
        </BrowserRouter>
    </StrictMode>
);
