import React, { useCallback, useEffect, useState } from 'react';

import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import CustomTooltip from './chart/CustomTooltip';
import RenderActiveShape from './chart/RenderActiveShape';
import { borderStyle, textStyle } from './commonStyle/commonLeaveStyle';
import './styles.css';

const data = [
    { name: 'Auto Approved Leave', value: 30 },
    { name: 'Approved Leave', value: 70 },
];

const COLORS = ['#FF8C21', '#0077C0'];

const ApprovedLeaveChart = ({ chartData }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [pieData, setPieData] = useState([]);
    const [tooltipData, setTooltipData] = useState([]);

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    useEffect(() => {
        const arr = [];
        let tooltipArr = {};
        chartData.map((data, index) => {
            if (data.totalApproveLeave || data.totalApproveLeave >= 0) {
                const newData = {};
                (newData.name = 'Approved Leave'),
                    (newData.value = data.totalApproveLeave),
                    arr.push(newData);

                if (
                    data.totalAutoApproveLeave ||
                    data.totalAutoApproveLeave >= 0
                ) {
                    const newData = {};
                    (newData.name = 'Auto Approved Leave'),
                        (newData.value = data.totalAutoApproveLeave),
                        arr.push(newData);
                }
                if (
                    data.totalApproveLeaveRatio ||
                    data.totalApproveLeaveRatio >= 0
                ) {
                    tooltipArr = {
                        ...tooltipArr,
                        totalApproveLeaveRatio: data.totalApproveLeaveRatio,
                    };
                }
                if (
                    data.totalAutoApproveLeaveRatio ||
                    data.totalAutoApproveLeaveRatio >= 0
                ) {
                    tooltipArr = {
                        ...tooltipArr,
                        totalAutoApproveLeaveRatio:
                            data.totalAutoApproveLeaveRatio,
                    };
                }
            }
        });

        setPieData(arr);
        setTooltipData(tooltipArr);
    }, [chartData]);

    return (
        <Box {...borderStyle}>
            <Text {...textStyle}>Total Approved & Auto-approved Leaves</Text>
            <PieChart width={490} height={400}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={<RenderActiveShape />}
                    data={pieData}
                    cx={250}
                    cy={300}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={150}
                    outerRadius={230}
                    fill="#F9FAFB"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
                <Legend />
                <Tooltip
                    content={
                        <CustomTooltip
                            payload={pieData}
                            tooltipData={tooltipData}
                        />
                    }
                />
            </PieChart>
        </Box>
    );
};

ApprovedLeaveChart.propTypes = {
    chartData: PropTypes.any,
};

export default ApprovedLeaveChart;
