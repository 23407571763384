import Service from './index';

const LEAVE_SEARCH_PATH = `api/admin/leave/forecasted-leave/distribution`;
const AUTOMATICALLY_APPROVED_LEAVES_PATH = `api/admin/leave/approved/report/lead`;
const APPROVED_LEAVES_PATH = `api/admin/leave/all/employee-leave-report`;
const DOWNLOAD = `api/admin/leave/approved-auto-approved-leaves/report/download`;
const SINGLE_DOWNLOAD = `api/admin/leave/employee-leave-report/download`;
const SINGLE_EMPLOYEE_DETAILS = `api/admin/leave`;

export default class LeaveManager {
    static async getEmployeeLeaveDetails(
        id,
        selectedYear,
        page = 0,
        size = 10
    ) {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            year: selectedYear,
        }).toString();
        return Service.get(
            `${SINGLE_EMPLOYEE_DETAILS}/employee/detail/${id}?${params}`,
            {
                data: {},
            }
        ).then(response => response.data);
    }
    static async getApprovedLeaves(getId, filteredData, selectedYear) {
        const searchObj = {};
        getId && (searchObj['id'] = getId);
        filteredData && (searchObj['type'] = filteredData);
        selectedYear && (searchObj['year'] = selectedYear);
        const params = new URLSearchParams(searchObj).toString();
        return Service.get(`${LEAVE_SEARCH_PATH}?${params}`, {
            data: {},
        }).then(response => response.data);
    }

    static async getApprovedLeavesReport(
        year,
        supervisorId,
        partnerId,
        employeeId,
        page = 0,
        size = 10
    ) {
        const formattedLeadsId = Array.isArray(supervisorId)
            ? supervisorId.join(':')
            : supervisorId;
        const formattedPartnersId = Array.isArray(partnerId)
            ? partnerId.join(':')
            : partnerId;
        const formattedEmployeeId = Array.isArray(employeeId)
            ? employeeId.join(':')
            : employeeId;

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            year: year || '',
        });

        if (year) {
            params.append('year', year);
        }
        if (formattedLeadsId) {
            params.append('supervisorId', formattedLeadsId);
        }
        if (formattedPartnersId) {
            params.append('partnerId', formattedPartnersId);
        }
        if (formattedEmployeeId) {
            params.append('employeeId', formattedEmployeeId);
        }

        return Service.get(`${APPROVED_LEAVES_PATH}?${params.toString()}`, {
            data: {},
        })
            .then(response => response.data)
            .catch(error => {
                console.error(
                    'Error fetching automatically approved leaves:',
                    error
                );
                throw error;
            });
    }
    static async getAutomaticallyApprovedLeaves(
        selectedYear,
        leadsId,
        page = 0,
        size = 10
    ) {
        const formattedLeadsId = Array.isArray(leadsId)
            ? leadsId.join(':')
            : leadsId;

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (selectedYear) {
            params.append('year', selectedYear);
        }
        if (formattedLeadsId) {
            params.append('leadsId', formattedLeadsId);
        }

        return Service.get(
            `${AUTOMATICALLY_APPROVED_LEAVES_PATH}?${params.toString()}`,
            {
                data: {},
            }
        )
            .then(response => response.data)
            .catch(error => {
                console.error(
                    'Error fetching automatically approved leaves:',
                    error
                );
                throw error;
            });
    }
    static async getDataDownload(year, format, employeeId, isAutoApprovedData) {
        const formattedEmployeeId = Array.isArray(employeeId)
            ? employeeId.join(':')
            : employeeId;

        const params = new URLSearchParams({
            isAutoApprovedData: isAutoApprovedData.toString(),
            format: format.toString(),
        });

        if (year) {
            params.append('year', year);
        }
        if (isAutoApprovedData === false && formattedEmployeeId) {
            params.append('employeeId', formattedEmployeeId);
        }
        if (isAutoApprovedData === true && formattedEmployeeId) {
            params.append('leadsId', formattedEmployeeId);
        }

        const url = `${DOWNLOAD}?${params.toString()}`;
        console.log('Fetching data from:', url);

        try {
            const response = await Service.get(url, {
                responseType: 'arraybuffer',
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching data for download:', error);
            throw error;
        }
    }
    static async getSingleDataDownload(year, format, employeeId) {
        const params = new URLSearchParams({
            format: format.toString(),
        });

        if (year) {
            params.append('year', year);
        }

        const url = `${SINGLE_DOWNLOAD}/${employeeId}?${params.toString()}`;
        console.log('Fetching data from:', url);

        try {
            const response = await Service.get(url, {
                responseType: 'arraybuffer',
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching data for download:', error);
            throw error;
        }
    }
}
