import React, { useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import '../../assets/css/index.css';
import activeToggle from '../../assets/icons/active-toggle.svg';
import downArrow from '../../assets/icons/arrow-down.svg';
import toggle from '../../assets/icons/toggle.svg';

const MultipleSelectDropDown = ({
    data,
    placeholder,
    onChange,
    identifier,
    selectedItems,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    const handleSelect = item => {
        const alreadySelected = selectedItems.some(
            selected => selected[`${identifier}`] === item[`${identifier}`]
        );

        let updatedItems;
        if (alreadySelected) {
            updatedItems = selectedItems.filter(
                selected => selected[`${identifier}`] !== item[`${identifier}`]
            );
        } else {
            updatedItems = [...selectedItems, item];
        }

        onChange(updatedItems);
    };

    const getPlaceholderText = () => {
        if (selectedItems.length === 1) return `${placeholder} selected`;
        if (selectedItems.length > 1) return `Multiple ${placeholder} selected`;
        return placeholder;
    };

    return (
        <Box position="relative">
            <Flex
                width="340px"
                justifyContent="space-between"
                alignItems="center"
                border="1px"
                borderColor="rgba(17, 17, 19, 0.20)"
                borderRadius="4px"
                padding="9px"
                cursor="pointer"
                onClick={toggleDropdown}
            >
                <Text
                    color="rgba(17, 17, 19, 0.60)"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {getPlaceholderText()}
                </Text>
                <img src={downArrow} alt="Toggle dropdown" />
            </Flex>
            {isOpen && (
                <Box
                    position="absolute"
                    width="316px"
                    maxHeight="242px"
                    padding="12px"
                    borderRadius="4px"
                    border="1px"
                    borderColor="#D3D5DA"
                    background="#ffffff"
                    boxShadow="0px 4px 8px 0px rgba(100, 100, 102, 0.10)"
                    overflowY="scroll"
                    zIndex="10"
                    top="50px"
                    onMouseLeave={closeDropdown}
                >
                    {data?.map(item => {
                        const isSelected = selectedItems.some(
                            selected =>
                                selected[`${identifier}`] ===
                                item[`${identifier}`]
                        );
                        return (
                            <Flex
                                key={item.id}
                                padding="12px"
                                alignItems="center"
                                borderRadius="2.667px"
                                _hover={{ bg: '#F9FAFB' }}
                                cursor="pointer"
                                onClick={() => handleSelect(item)}
                                gap={2}
                            >
                                <img
                                    src={isSelected ? activeToggle : toggle}
                                    alt={
                                        isSelected ? 'Selected' : 'Not selected'
                                    }
                                />
                                <Text
                                    color="rgba(17, 17, 19, 0.60)"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    {item.name}
                                </Text>
                            </Flex>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

MultipleSelectDropDown.propTypes = {
    data: PropTypes.any,
    selectedItems: PropTypes.any,
    identifier: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MultipleSelectDropDown;
