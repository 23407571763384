import React, { useState } from 'react';

import {
    Box,
    Flex,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useTab,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { ReactComponent as BrokenClockActive } from '../../assets/icons/broken-clock-active.svg';
import { ReactComponent as BrokenClock } from '../../assets/icons/broken-clock.svg';
import { ReactComponent as TickCircleActive } from '../../assets/icons/tick-circle-active.svg';
import { ReactComponent as TickCircle } from '../../assets/icons/tick-circle.svg';
import { borderStyle, textStyle } from './commonStyle/commonLeaveStyle';
import ApprovedLeaveTabPanel from './tabs/ApprovedLeaveTabPanel';
import AutoApprovedLeaveTabPanel from './tabs/AutoApprovedLeaveTabPane';

const AllEmployeeLeaveData = ({
    leaves,
    partners,
    leads,
    selectedYear,
    employee,
}) => {
    const CustomTab = React.forwardRef((props, ref) => {
        const tabProps = useTab({ ...props, ref });
        const isSelected = !!tabProps['aria-selected'];

        return (
            <Flex direction="row" gap="12px" {...tabProps}>
                <Flex
                    height="50px"
                    width="50px"
                    bg="#F4F4F6"
                    rounded="full"
                    alignItems="center"
                    justifyContent="center"
                >
                    {isSelected ? props.inActive : props.active}
                </Flex>
                {tabProps.children}
            </Flex>
        );
    });
    CustomTab.displayName = 'CustomTab';

    const [dataType, setDataType] = useState(false);

    const handleTabChange = index => {
        setDataType(index === 1);
    };

    return (
        <Box {...borderStyle}>
            <Text {...textStyle}>All Employee Leave Data</Text>
            <Box pt="16px">
                <Tabs variant="unstyled" onChange={handleTabChange}>
                    <TabList gap="12px">
                        <CustomTab
                            active={<TickCircle />}
                            inActive={<TickCircleActive />}
                            w="100%"
                            border="1px"
                            borderRadius="4px"
                            borderColor="#D3D5DA"
                            px="16px"
                            py="24px"
                            justifyContent="flex-start"
                            _selected={{
                                border: '1px',
                                borderColor: '#0077C0',
                                borderRadius: '4px',
                                bg: '#F5FBFF',
                                boxShadow:
                                    '0px 4px 24px 0px rgba(0, 119, 192, 0.16)',
                            }}
                        >
                            <Box>
                                <Text
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="#6C727F"
                                    textAlign="start"
                                >
                                    Approved Leaves
                                </Text>
                            </Box>
                        </CustomTab>
                        <CustomTab
                            active={<BrokenClock />}
                            inActive={<BrokenClockActive />}
                            w="100%"
                            border="1px"
                            borderRadius="4px"
                            borderColor="#D3D5DA"
                            px="16px"
                            py="24px"
                            justifyContent="flex-start"
                            _selected={{
                                border: '1px',
                                borderColor: '#FF8C21',
                                borderRadius: '4px',
                                bg: '#FFF7EF',
                                boxShadow:
                                    '0px 4px 24px 0px rgba(0, 119, 192, 0.16)',
                            }}
                        >
                            <Box>
                                <Text
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="#6C727F"
                                    textAlign="start"
                                >
                                    Automatically Approved Leaves
                                </Text>
                            </Box>
                        </CustomTab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <ApprovedLeaveTabPanel
                                selectedYear={selectedYear}
                                leaves={leaves?.approved}
                                partners={partners}
                                leads={leads}
                                employee={employee}
                                dataType={dataType}
                            />
                        </TabPanel>
                        <TabPanel>
                            <AutoApprovedLeaveTabPanel
                                selectedYear={selectedYear}
                                approvedType="autoApproved"
                                leads={leads}
                                dataType={dataType}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    );
};
AllEmployeeLeaveData.propTypes = {
    props: PropTypes.any,
    partners: PropTypes.any,
    leads: PropTypes.any,
    ref: PropTypes.any,
    active: PropTypes.any,
    inActive: PropTypes.any,
    leaves: PropTypes.object,
    employee: PropTypes.any,
    selectedYear: PropTypes.string,
};

export default AllEmployeeLeaveData;
