import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { Box, useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { ReactComponent as DownArrow } from '../../../assets/icons/arrow-down.svg';
import info from '../../../assets/icons/info.svg';
import CommonPagination from '../../../common/CommonPagination';
import {
    buildLeavePath,
    buildLeaveSinglePath,
    buildLeavesPath,
} from '../../../constants/pathNameConstants';
import LeaveManager from '../../../service/leave-manager';
import '../styles.css';
import Filter from './Filter';

const columns = [
    {
        id: 1,
        name: 'Employee Name',
        selector: row => row.name,
        sortable: false,
        reorder: false,
    },
    {
        id: 2,
        name: 'Designation',
        selector: row => row.designation,
        sortable: false,
        reorder: false,
    },
    {
        id: 3,
        name: 'Partner',
        selector: row => row.partners.map(partner => partner.name).join(', '),
        sortable: false,
        reorder: false,
    },
    {
        id: 4,
        name: (
            <Box>
                <a
                    data-tooltip-variant="light"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Leave requested one day before or on the same day."
                    data-tooltip-place="bottom"
                >
                    <span>Unplanned leave</span>{' '}
                    <img className="tooltip-info" src={info} alt="info" />
                </a>
                <Tooltip id="my-tooltip" className="lightTooltip" />
            </Box>
        ),
        selector: row => row.unplannedLeave,
        sortable: true,
    },
    {
        id: 5,
        name: (
            <Box>
                <a
                    data-tooltip-variant="light"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Calculated with last 12 months leave records."
                    data-tooltip-place="bottom"
                >
                    <span>Current monthly avg. leave</span>{' '}
                    <img className="tooltip-info" src={info} alt="info" />
                </a>
                <Tooltip id="my-tooltip" className="lightTooltip" />
            </Box>
        ),
        selector: row => row.averageMonthlyLeave,
        sortable: true,
    },

    {
        id: 6,
        name: (
            <Box>
                <a
                    data-tooltip-variant="light"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Calculated considering all pending and approved leave for a year."
                    data-tooltip-place="bottom"
                >
                    <span>Forecasted leave this year</span>{' '}
                    <img className="tooltip-info" src={info} alt="info" />
                </a>
                <Tooltip id="my-tooltip" className="lightTooltip" />
            </Box>
        ),
        selector: row => {
            const forecastedLeave = parseInt(row.forecastedLeaveValue, 10);

            let backgroundColor = '';
            let textColor = '';

            if (forecastedLeave <= 20) {
                backgroundColor = '#E5E6EB';
                textColor = '#394150';
            } else if (forecastedLeave >= 21 && forecastedLeave <= 25) {
                backgroundColor = 'yellow';
                textColor = 'white';
            } else if (forecastedLeave > 25) {
                backgroundColor = 'red';
                textColor = 'white';
            }
            return (
                <div
                    style={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                        padding: '5px 10px',
                        borderRadius: '4px',
                        textAlign: 'center',
                    }}
                >
                    {forecastedLeave}
                </div>
            );
        },
        sortable: true,
        reorder: true,
    },
];

const ApprovedLeaveTabPanel = ({
    approvedType,
    employee,
    partners,
    leads,
    selectedYear,
    dataType,
}) => {
    let { page, size } = useParams();
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();

    const [selectedLeads, setSelectedLeads] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [searchEmployee, setSearchEmployee] = useState();
    const [data, setData] = useState();
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const toast = useToast();
    const [selectedDownloadType, setSelectedDownloadType] = useState(null);

    const handleSelectedItem = selectedData => {
        if (selectedData.type === 'leads') {
            const leadIds = selectedData.items.map(lead => lead.employeeId);
            setSelectedLeads(leadIds);
        } else if (selectedData.type === 'partners') {
            const partnersIds = selectedData.items.map(partners => partners.id);
            setSelectedPartners(partnersIds);
        }
    };
    const handleSearchEmployee = searchItem => {
        setSearchEmployee(searchItem);
    };
    const handleDownloadSelect = type => {
        setSelectedDownloadType(type);
        setTimeout(() => {
            setSelectedDownloadType(null);
        }, 2000);
    };

    useEffect(() => {
        fetchApprovedLeavesReport();
        if (selectedDownloadType) {
            fetchDataDownload();
        }
    }, [
        selectedLeads,
        selectedPartners,
        searchEmployee,
        selectedYear,
        selectedDownloadType,
        dataType,
        page,
        size,
    ]);

    async function fetchApprovedLeavesReport() {
        setDataLoaderOpen(true);
        try {
            const supervisorId = selectedLeads;
            const partnerId = selectedPartners;
            const employeeId = searchEmployee || null;
            const year = selectedYear || currentYear;
            const response = await LeaveManager.getApprovedLeavesReport(
                year,
                supervisorId,
                partnerId,
                employeeId,
                page,
                size
            );
            setData(response);
            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);

            toast({
                title: 'Failed to fetch Auto approved leave',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
    }
    async function fetchDataDownload() {
        setDataLoaderOpen(true);
        try {
            const employeeId = searchEmployee || null;
            const year = selectedYear || currentYear;
            const format = selectedDownloadType;
            const isAutoApprovedData = dataType;

            const response = await LeaveManager.getDataDownload(
                year,
                format,
                employeeId,
                isAutoApprovedData
            );

            const blob = new Blob([response], {
                type: format === 'pdf' ? 'application/pdf' : 'text/csv',
            });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `leave-report-${year}.${format}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);

            toast({
                title: 'Failed to fetch Auto approved leave',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    async function navigateToEmployeeDetails(employeeId) {
        navigate(buildLeavePath(employeeId));
    }
    function setPageNumber(pageNumber) {
        page = pageNumber;
        navigate(buildLeavesPath(page, size));
    }

    return (
        <Box className="App">
            <Filter
                approvedType={approvedType}
                partners={partners}
                leads={leads}
                selectedItem={handleSelectedItem}
                searchEmployee={handleSearchEmployee}
                employee={employee}
                selectDownload={handleDownloadSelect}
            />
            {isDataLoaderOpen ? (
                <Box className="loader" />
            ) : (
                <DataTable
                    columns={columns}
                    data={data?.data}
                    sortIcon={<DownArrow />}
                    onRowClicked={row =>
                        navigateToEmployeeDetails(row.employeeId)
                    }
                />
            )}

            <CommonPagination
                page={page}
                size={size}
                totalPages={data?.meta?.totalPages}
                setPageNo={setPageNumber}
            />
        </Box>
    );
};

ApprovedLeaveTabPanel.propTypes = {
    approvedType: PropTypes.string,
    dataType: PropTypes.any,
    partners: PropTypes.any,
    leads: PropTypes.any,
    employee: PropTypes.any,
    selectedYear: PropTypes.string,
};

export default ApprovedLeaveTabPanel;
