import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { PropTypes } from 'prop-types';

import Tooltip from '../../common/tooltip/Tooltip';
import { formatDate } from '../../helper/dateHelper/dateUtils.js';
import HolidayManagement from '../../service/holiday-management';
import { addHolidayValidation } from '../../validation/dataValidation';

function HolidayEditModal({ holidayEdit, onChange }) {
    const [dateRange, setDateRange] = useState([
        holidayEdit.startFrom,
        holidayEdit.endAt,
    ]);
    const [initialData, setInitialData] = useState({
        name: holidayEdit.name,
        startFrom: holidayEdit.startFrom,
        endAt: holidayEdit.endAt,
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = useState(holidayEdit.name);
    const finalRef = React.useRef(null);
    const initialRef = React.useRef(null);
    const toast = useToast();
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addHolidayValidation),
    });

    const [tooltip, setTooltip] = useState({
        visible: false,
        text: '',
        top: 0,
        left: 0,
    });

    const handleMouseEnter = (event, date) => {
        if (isHoliday(date)) {
            const rect = event.target.getBoundingClientRect();
            setTooltip({
                visible: true,
                contents: [`Weekend can’t be`, ` selected as holiday`],
                top: rect.top - 52,
                left: rect.left + rect.width / 2,
            });
        }
    };

    const handleMouseLeave = () => {
        setTooltip({ visible: false, text: '', top: 0, left: 0 });
    };

    const isHoliday = date => {
        const day = date.getDay();
        return day === 5 || day === 6;
    };

    const handleModalClose = () => {
        reset(initialData);
        setDateRange([initialData.startFrom, initialData.endAt]);
        onClose();
    };

    const update = async data => {
        const payload = {
            id: holidayEdit.id,
            name: data.name,
            startFrom: formatDate(dateRange[0]),
            endAt: formatDate(dateRange[1]),
        };
        setDataLoaderOpen(true);
        try {
            const data = await HolidayManagement.updateHoliday(payload);
            onClose();
            onChange(data);
            toast({
                title: 'Successfully Updated',
                description: 'Holiday has been updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Update Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    const hasChanges = () => {
        if (!dateRange || dateRange.length !== 2) {
            return false;
        }
        return (
            name !== initialData.name ||
            formatDate(dateRange[0]) !== initialData.startFrom ||
            formatDate(dateRange[1]) !== initialData.endAt
        );
    };

    return (
        <>
            <Flex align="center" onClick={onOpen} cursor="pointer">
                <Text>Edit</Text>
            </Flex>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={handleModalClose}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit holiday</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmit(update)}>
                            <FormControl pt={2} isInvalid={errors.name}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="name"
                                    color="#464646"
                                >
                                    Name
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '100%', lg: '100%' }}
                                    >
                                        <Input
                                            {...register('name')}
                                            value={name}
                                            bgColor="white"
                                            color="rgba(17, 17, 19, 0.60)"
                                            width="100%"
                                            border="1px"
                                            borderColor="rgba(17, 17, 19, 0.2)"
                                            fontSize="14px"
                                            autoComplete="off"
                                            borderRadius="4px"
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            onChange={e => {
                                                setName(e.target.value);
                                                register('name').onChange(e);
                                            }}
                                        />

                                        <Flex>
                                            <FormErrorMessage>
                                                {errors.name &&
                                                    errors.name.message}
                                            </FormErrorMessage>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </FormControl>
                            <FormControl pt={6} isInvalid={errors.holidayDate}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="holidayDate"
                                    color="#464646"
                                >
                                    Date
                                </FormLabel>
                                <div
                                    className="date-picker-wrapper"
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                >
                                    <DateRangePicker
                                        {...register('holidayDate')}
                                        onChange={value => {
                                            setDateRange(value);
                                            register('holidayDate').onChange({
                                                target: { value },
                                            });
                                        }}
                                        value={dateRange}
                                        tileDisabled={({ date }) =>
                                            isHoliday(date)
                                        }
                                        tileClassName={({ date }) =>
                                            isHoliday(date) ? 'holiday' : ''
                                        }
                                        tileContent={({ date, view }) =>
                                            view === 'month' &&
                                            isHoliday(date) ? (
                                                <div
                                                    className="holiday-hover-area"
                                                    onMouseEnter={e =>
                                                        handleMouseEnter(
                                                            e,
                                                            date
                                                        )
                                                    }
                                                    onMouseLeave={
                                                        handleMouseLeave
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                ></div>
                                            ) : null
                                        }
                                        clearIcon={null}
                                    />
                                    <Tooltip
                                        visible={tooltip?.visible}
                                        contents={tooltip?.contents}
                                        top={tooltip?.top}
                                        left={tooltip?.left}
                                    />
                                </div>
                                <Flex>
                                    <FormErrorMessage>
                                        {dateRange === null &&
                                            'Holiday date is required...'}
                                    </FormErrorMessage>
                                </Flex>
                            </FormControl>
                            <Flex
                                direction="row"
                                justifyContent="flex-end"
                                mt="300px"
                                w={{ lg: '100%', base: '100%' }}
                            >
                                <Text
                                    cursor="pointer"
                                    fontSize="12px"
                                    pr="16px"
                                    color="#3F3F4E"
                                    alignSelf="center"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </Text>
                                <Button
                                    type="submit"
                                    w="119px"
                                    h="30px"
                                    fontSize="12px"
                                    bgColor="#0077C0"
                                    color="white"
                                    disabled={
                                        !isValid ||
                                        !hasChanges() ||
                                        dateRange?.length === 0
                                    }
                                    _focus={{
                                        borderColor: 'none',
                                    }}
                                    _hover={{
                                        color: 'white',
                                        bgColor: '#0077C0',
                                    }}
                                    _disabled={{
                                        cursor: 'not-allowed',
                                        bgColor: '#DDDDDD !important',
                                        color: '#9A9A9A !important',
                                    }}
                                >
                                    Update
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

HolidayEditModal.propTypes = {
    holidayEdit: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default HolidayEditModal;
