import React, { useState } from 'react';

import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import download from '../../assets/icons/downloadIcon.svg';

const Download = ({ selectDownload }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleBox = () => setIsOpen(prevState => !prevState);

    const handleDownload = type => {
        selectDownload(type);
        setIsOpen(false);
    };

    return (
        <Box position="relative">
            <Box
                border="1px"
                borderColor="rgba(17, 17, 19, 0.20)"
                borderRadius="4px"
                padding="8px 12px"
                cursor="pointer"
                onClick={toggleBox}
            >
                <img src={download} alt="download" />
            </Box>

            {isOpen && (
                <Box
                    position="absolute"
                    width="316px"
                    maxHeight="242px"
                    padding="12px"
                    borderRadius="4px"
                    border="1px"
                    borderColor="#D3D5DA"
                    background="#ffffff"
                    boxShadow="0px 4px 8px 0px rgba(100, 100, 102, 0.10)"
                    overflowY="scroll"
                    zIndex="10"
                    top="50px"
                    right="0px"
                >
                    <Text
                        padding="12px"
                        borderRadius="2.667px"
                        _hover={{ bg: '#F9FAFB' }}
                        cursor="pointer"
                        color="rgba(17, 17, 19, 0.60)"
                        fontSize="14px"
                        fontWeight="400"
                        onClick={() => handleDownload('pdf')}
                    >
                        Download as PDF
                    </Text>
                    <Text
                        padding="12px"
                        borderRadius="2.667px"
                        _hover={{ bg: '#F9FAFB' }}
                        cursor="pointer"
                        color="rgba(17, 17, 19, 0.60)"
                        fontSize="14px"
                        fontWeight="400"
                        onClick={() => handleDownload('csv')}
                    >
                        Download as CSV
                    </Text>
                </Box>
            )}
        </Box>
    );
};

Download.propTypes = {
    selectDownload: PropTypes.func.isRequired,
};

export default Download;
