import React, { useState, useEffect } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import downArrow from '../../assets/icons/arrow-down.svg';

const CustomDropDown = ({ data, placeholder, onChange, distributionType }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    };

    const handleSelect = item => {
        setSelectedItem(item.name);
        onChange(item.employeeId || item.id);
        setIsOpen(false);
    };

    useEffect(() => {
        setSelectedItem(null);
    }, [distributionType]);

    return (
        <Box position="relative">
            <Flex
                width="316px"
                justifyContent="space-between"
                alignItems="center"
                border="1px"
                borderColor="rgba(17, 17, 19, 0.20)"
                borderRadius="4px"
                padding="9px"
                cursor="pointer"
                onClick={toggleDropdown}
            >
                <Text
                    color="rgba(17, 17, 19, 0.60)"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {selectedItem || placeholder}
                </Text>
                <img src={downArrow} alt="Toggle dropdown" />
            </Flex>
            {isOpen && (
                <Box
                    position="absolute"
                    width="316px"
                    maxHeight="242px"
                    padding="12px"
                    borderRadius="4px"
                    border="1px"
                    borderColor="#D3D5DA"
                    background="#ffffff"
                    boxShadow="0px 4px 8px 0px rgba(100, 100, 102, 0.10)"
                    overflowY="scroll"
                    zIndex="10"
                    top="50px"
                >
                    {data.map(item => (
                        <Box
                            key={item.id}
                            padding="12px"
                            borderRadius="2.667px"
                            _hover={{ bg: '#F9FAFB' }}
                            cursor="pointer"
                            onClick={() => handleSelect(item)}
                        >
                            <Text
                                color="rgba(17, 17, 19, 0.60)"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {item.name}
                            </Text>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

CustomDropDown.propTypes = {
    data: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    distributionType: PropTypes.object.isRequired,
};

export default CustomDropDown;
