import React, { useEffect, useState } from 'react';
//import filterIcon from '../../assets/icons/filterIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';

import { Flex, Spacer, useToast } from '@chakra-ui/react';

import CommonPagination from '../../common/CommonPagination';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import { SET_EMPLOYEES } from '../../constants/actionTypeConstant';
import { EMPLOYEE_LIST_BREADCRUMB } from '../../constants/breadcrumbData';
import { PERMANENT, TERMINATED } from '../../constants/employeeTypes';
import { buildEmployeesPath } from '../../constants/pathNameConstants';
import { useInformation } from '../../contexts/informationContext';
import EmployeeManagement from '../../service/employee-management';
import EmployeeTableResults from '../components/EmployeeTableResults';
import EmployeesFeatures from '../components/EmployeesFeatures';

export default function EmployeeList() {
    const PAGE_TITLE = 'Employee List';
    const navigate = useNavigate();
    const { state, dispatch } = useInformation();
    const toast = useToast();
    let { page, size } = useParams();
    const [results, setResults] = useState(state.employeesResult);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [searchName, setSearchName] = useState('');

    useEffect(() => {
        if (searchName.length >= 1) {
            employeeSearchByName(searchName);
        } else {
            fetchEmployeesData();
        }

        return () => {
            dispatch({ type: SET_EMPLOYEES, payload: { content: [] } });
        };
    }, [page, size]);

    async function employeeSearchByName(name) {
        if (name.length >= 1) {
            try {
                const response =
                    await EmployeeManagement.getEmployeesByNameAndStatus(
                        name,
                        PERMANENT + ',' + TERMINATED,
                        page,
                        size
                    );
                setResults(response);
                dispatch({ type: SET_EMPLOYEES, payload: response });
            } catch (error) {
                toast({
                    title: 'Search Fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } else {
            const response = await EmployeeManagement.findEmployees(page, size);
            setResults(response);
            dispatch({ type: SET_EMPLOYEES, payload: response });
            navigate(buildEmployeesPath(page, size));
        }
    }
    async function fetchEmployeesData() {
        setDataLoaderOpen(true);
        try {
            const response = await EmployeeManagement.findEmployees(page, size);
            setResults(response);
            dispatch({ type: SET_EMPLOYEES, payload: response });
            navigate(buildEmployeesPath(page, size));
            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Search Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    function setPageNumber(pageNumber) {
        page = pageNumber;
        navigate(buildEmployeesPath(page, size));
    }

    if (!results || isDataLoaderOpen)
        return <DataLoader isOpen={isDataLoaderOpen} />;

    return (
        <>
            <CustomBreadcrumb allBreadcrumbData={EMPLOYEE_LIST_BREADCRUMB} />

            <Flex direction="row">
                <PageTitle title={PAGE_TITLE} />

                <Spacer />

                <EmployeesFeatures
                    searchName={searchName}
                    setSearchName={setSearchName}
                    employeeSearchByName={employeeSearchByName}
                    setPageNumber={setPageNumber}
                />
            </Flex>

            <EmployeeTableResults results={results} />
            <CommonPagination
                page={page}
                size={size}
                totalPages={results.totalPages}
                setPageNo={setPageNumber}
            />
        </>
    );
}
