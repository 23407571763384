import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Image,
    Text,
} from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import homeIcon from '../assets/icons/homeIcon.svg';
import { breadcrumbData } from '../constants/breadcrumbData';

const CustomBreadcrumb = ({ allBreadcrumbData }) => {
    const navigate = useNavigate();

    function BreadcrumbLinkHandler(addressLink) {
        navigate(addressLink);
    }

    const showTitle = (data, title) => {
        return (
            <Flex direction="row">
                {data === 'Home' ? (
                    <Image pr={2} w="20px" h="12px" src={homeIcon} />
                ) : (
                    ''
                )}
                <Text>{title}</Text>
            </Flex>
        );
    };

    function returnBreadcrumb() {
        return allBreadcrumbData.map((data, pos) => {
            return breadcrumbData.map((breadcrumb, index) => {
                if (data === breadcrumb.key) {
                    const title = breadcrumb.key;
                    const url = breadcrumb.value;
                    if (pos < allBreadcrumbData.length - 1) {
                        return (
                            <BreadcrumbItem
                                fontSize={['10px', '12px']}
                                color="#828282"
                                key={index}
                            >
                                <BreadcrumbLink
                                    onClick={() => BreadcrumbLinkHandler(url)}
                                >
                                    {showTitle(data, title)}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        );
                    } else {
                        return (
                            <BreadcrumbItem
                                fontSize={['10px', '13px']}
                                isCurrentPage
                                key={index}
                                color="#4F4F4F"
                                fontWeight="medium"
                            >
                                <BreadcrumbLink>
                                    {showTitle(data, title)}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        );
                    }
                }
            });
        });
    }

    return (
        <Breadcrumb
            p="6px"
            mt="62px"
            fontWeight="medium"
            fontSize="12px"
            bgColor="#F2F2F2"
            border="1px solid"
            borderColor="#EEEEEE"
            borderRadius="4px"
        >
            {returnBreadcrumb()}
        </Breadcrumb>
    );
};

CustomBreadcrumb.propTypes = {
    allBreadcrumbData: PropTypes.array.isRequired,
};

export default CustomBreadcrumb;
